import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Tooltip from "@material-ui/core/Tooltip";
import Magnifier from "react-magnifier";
import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";
import Style from "./style.js";
import PromoBanner from "../../../../components/promoBanner/index.js";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const TabPanel = (props) => {
  const style = Style();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper elevation={3} className={style.paperTab}>
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        </Paper>
      )}
    </div>
  );
};

const Layout = ({
  dataProduct,
  userFavorites,
  onFavorite,
  onChangeTab,
  tabValue,
  onChangeImageToView,
  imageToView,
  onChangeColor,
  colorId,
  onChangeQuantity,
  onAddOnCart,
  successAddToCart,
  warningAddToCart,
  errorAddToCart,
  onCloseSuccessAddToCart,
  onCloseErrorAddToCart,
  total,
  dataSummaryProductOnCart,
  onRemoveOnCart,
  successRemoveOnCart,
  onCloseSuccessRemoveOnCart,
  onGoToShop,
  onGoToCart,
  isMobile,
  indexOfColor,
  modalZoomColor,
  openModalZoomColor,
  closeModalZoomColor,
  colorToZoom,
  formAddToCart,
}) => {
  const style = Style();

  const [hidePrice, setHidePrice] = useState(localStorage.getItem("hidePricingAgent"));

  window.addEventListener('storage', () => {
    setHidePrice(!!localStorage.getItem("hidePricingAgent"));
  })

  return (
    <>
      <div style={{ width: "100%" }}>
        <PromoBanner />
      </div>
      <div style={{ marginBottom: "1rem", marginTop: "1rem" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="inherit" className={style.breadCrumbsText} onClick={onGoToShop}>Shop</Typography>
          <Typography color="textPrimary">{dataProduct.product.name} {dataProduct.product.fineness ? dataProduct.product.fineness.name : null} - {dataProduct.product.code}</Typography>
        </Breadcrumbs>
        <Grid container className={style.gridContainer} spacing={0}>
          <div className={style.firstCol} >
            <Paper elevation={3}>
              <div className={style.productImageContainer}>
                {
                  isMobile ? (
                    <Magnifier
                      className={style.imageMagnifier}
                      width={"auto"}
                      mgWidth={200}
                      mgHeight={200}
                      style={{ borderRadius: "4px", maxHeight: "480px", width: "auto", height: "auto", margin: "30px 0 40px 0", maxWidth: "280px" }}
                      zoomFactor={1.5}
                      height={"100%"}
                      src={imageToView ? imageToView : dataProduct.product.image}
                    />
                  ) : (
                    <Magnifier
                      className={style.imageMagnifier}
                      width={"auto"}
                      mgWidth={200}
                      mgHeight={200}
                      style={{ borderRadius: "4px", maxHeight: "480px", width: "auto", margin: "30px 0 40px 0", maxWidth: "400px" }}
                      zoomFactor={1.5}
                      height={"100%"}
                      src={imageToView ? imageToView : dataProduct.product.image}
                    />
                  )
                }

                <Button
                  className={style.productImageButton}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => window.open("/product-image/" + dataProduct.product.id)}
                  startIcon={<ZoomInIcon />}
                >Ingrandisci</Button>
              </div>
            </Paper>
            <div className={style.gridGallery}>
              <GridList className={style.gridList} cols={2.5}>
                <GridListTile className={style.galleryImage} key={"productGalleryImage"}>
                  <img className={style.thumbnailImage} src={dataProduct.product.image} alt={dataProduct.product.name} onClick={() => onChangeImageToView(dataProduct.product.image)} />
                </GridListTile>
                {
                  dataProduct.product.productsGallery.map((item, index) => (
                    <GridListTile className={style.galleryImage} key={index + "productGallery"}>
                      <img className={style.thumbnailImage} src={item.image} alt={dataProduct.product.name} onClick={() => onChangeImageToView(item.image)} />
                    </GridListTile>
                  ))
                }
              </GridList>
            </div>
          </div>
          <div className={style.secondCol}>
            <Paper elevation={3} className={style.paperProductInfo}>
              <div className={style.productTitleContainer}>
                <Typography variant="h5" className={style.productTitle}>{dataProduct.product.name} {dataProduct.product.fineness ? dataProduct.product.fineness.name : null} - {dataProduct.product.code}</Typography>
                <FormControlLabel
                  className={style.productHeart}
                  control={<Checkbox icon={<FavoriteBorder />} checked={userFavorites.includes(dataProduct.product.id) ? true : false} onClick={() => onFavorite(dataProduct.product.id)} checkedIcon={<Favorite style={{ color: "#ff5b60" }} />} />}
                />
              </div>
              <Divider />
              {dataProduct.product?.description &&
                <div className={style.productUseNoteContainer}>
                  {dataProduct.product?.description}
                </div>
              }
              {/* <div>
                <Typography variant="subtitle1" className={style.productSize}>Taglie {dataProduct.product.groupSize !== null ? dataProduct.product.groupSize.name : ""}</Typography>
              </div>
              <div className={style.productSizeContainer}>
                {
                  dataProduct && dataProduct.product.groupSize !== null && dataProduct.product.groupSize.sizes.length > 0 ? (
                    dataProduct.product.groupSize.sizes.map((item, index) => (
                      <span key={index + "groupSizes"} className={style.productSizeText}>{item.name + "  "}</span>
                    ))
                  ) : (
                    "Non ci sono taglie."
                  )
                }
              </div> */}
              <div>
                <Typography variant="subtitle1" className={style.productDescription}>Colori</Typography>
              </div>

              {
                dataProduct && dataProduct.product.outOfStock && JSON.parse(dataProduct.product.outOfStock.length) > 0 ? (
                  <div className={style.productColorsContainer}>
                    {
                      JSON.parse(dataProduct.product.outOfStock).map((item, index) => (
                        item.values && item.values?.length > 0 ?
                          <div>
                            <div style={{ position: "relative", width: "70px", height: "70px" }}>
                              <Tooltip key={index + "tooltipColors"} title={"ingrandisci colore: " + item.color.hex} placement="bottom">
                                <div>
                                  {JSON.parse(dataProduct.product.discountColors)?.[item.color.id] &&
                                    <div className={style.ribbon} style={{ top: 0, right: 0 }} onClick={() => openModalZoomColor(item.color.hex, item.color.image)}>
                                      <span className={style.ribbonSpanDiscount}>- {JSON.parse(dataProduct.product.discountColors)?.[item.color.id]}%</span>
                                    </div>
                                  }
                                  <Avatar variant="rounded" className={style.productColor} src={item.color.image} onClick={() => openModalZoomColor(item.color.hex, item.color.image)}></Avatar>
                                </div>
                              </Tooltip>
                            </div>
                            {item.color.hex}
                          </div>
                          :
                          <div>
                            <div style={{ position: "relative", width: "70px", height: "70px" }}>
                              <Tooltip key={index + "tooltipColors"} title={"ingrandisci colore: " + item.color.hex} placement="bottom">
                                <div>
                                  <div className={style.ribbon} style={{ top: 0, right: 0 }} onClick={() => openModalZoomColor(item.color.hex, item.color.image)}><span className={style.ribbonSpan}>ESAURITO</span></div>
                                  <Avatar variant="rounded" className={style.productColor} src={item.color.image} onClick={() => openModalZoomColor(item.color.hex, item.color.image)}></Avatar>
                                </div>
                              </Tooltip>
                            </div>
                            {item.color.hex}
                          </div>
                      ))
                    }
                  </div>
                ) : (
                  <div style={{ padding: "0.5rem 1rem 0 1rem" }}>
                    <span>Non ci sono colori.</span>
                  </div>
                )
              }
              {
                dataProduct.product.season !== null ? (
                  <>
                    <div>
                      <Typography variant="subtitle1" className={style.productPrice}>Stagione </Typography>
                    </div>
                    <div>
                      <span className={style.productPriceText}>{dataProduct.product.season !== null ? dataProduct.product.season.name : ""}</span>
                    </div>
                  </>
                ) : (
                  <></>
                )
              }
              {
                dataProduct.product.fineness !== null ? (
                  <>
                    <div>
                      <Typography variant="subtitle1" className={style.productPrice}>Finezza </Typography>
                    </div>
                    <div>
                      <span className={style.productPriceText}>{dataProduct.product.fineness !== null ? dataProduct.product.fineness.name : ""}</span>
                    </div>
                  </>
                ) : (
                  <></>
                )
              }
              {
                dataProduct.product.compositions !== null && dataProduct.product.compositions.length > 0 ? (
                  <>
                    <div>
                      <Typography variant="subtitle1" className={style.productPrice}>Composizione </Typography>
                    </div>
                    <div className={style.containerCompositionBox}>
                      {
                        dataProduct.product.compositions.map((item, key) => {
                          return (
                            <div key={item} className={style.compositionsBox}>{item.name}</div>
                          );
                        })
                      }
                    </div>
                  </>
                ) : (
                  <></>
                )
              }
              {
                dataProduct.product.tags !== null && dataProduct.product.tags.length > 0 ? (
                  <>
                    <div>
                      <Typography variant="subtitle1" className={style.productPrice}>Tag </Typography>
                    </div>
                    <div className={style.containerCompositionBox}>
                      {
                        dataProduct.product.tags.map((item, key) => {
                          return (
                            <span key={item} className={style.compositionsBox}>{item.name}</span>
                          );
                        })
                      }
                    </div>
                  </>
                ) : (
                  <></>
                )
              }
              {!hidePrice &&
                <>
                  <div>
                    <Typography variant="subtitle1" className={style.productPrice}>Prezzo</Typography>
                  </div>
                  <div>
                    {
                      dataProduct.product.sale && dataProduct.product.sale > 0 ? (
                        <div style={{ display: "flex" }}>
                          {dataProduct.product.sale === 0 ? (<p className={style.productPriceText}>Prezzo non disponibile. </p>) : <><p className={style.saleBanner}>in saldo</p><p className={style.productPriceText} style={{ textDecoration: "line-through", marginRight: "1.2px" }}>€{dataProduct.product.price}</p> <b>€{dataProduct.product.sale} iva esclusa</b></>}
                        </div>
                      ) : (
                        dataProduct.product.percentage_discount && dataProduct.product.percentage_discount > 0 ? (
                          <div style={{ display: "flex" }}>
                            {dataProduct.product.percentage_discount === 0 ? (<p className={style.productPriceText}>Prezzo non disponibile. </p>) : <><p className={style.saleBanner}>SCONTO {dataProduct.product.percentage_discount}%</p><p className={style.productPriceText} style={{ textDecoration: "line-through", marginRight: "1.2px" }}>€{dataProduct.product.price}</p> <b>€{(dataProduct.product.price - (dataProduct.product.price * (dataProduct.product.percentage_discount / 100))).toFixed(2)} iva esclusa</b></>}
                          </div>) : (
                          <p className={style.productPriceText}>{dataProduct.product.price === 0 ? "Prezzo non disponibile." : "€" + dataProduct.product.price + " iva esclusa"}</p>
                        )
                      )
                    }
                  </div>
                </>
              }
              <Divider style={{ marginTop: "1rem" }} />
              {
                dataProduct && dataProduct.product.stock === 0 ? (
                  <>
                    <div>
                      <Typography variant="h4" className={style.labelBuy} style={{ color: 'rgba(130,70,51,1) !important' }}>Acquista</Typography>
                      <Typography variant="subtitle1" className={style.productBuy} >Di seguito selezionare colore per l’acquisto:</Typography>
                    </div>
                    <Grid container className={style.gridContainerBuy} spacing={3}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className={style.gridBuyColors}>
                          {
                            dataProduct && JSON.parse(dataProduct.product.outOfStock.length) > 0 ? (
                              JSON.parse(dataProduct.product.outOfStock).map((item, index) => {
                                return (
                                  item.values && item.values?.length > 0 ? (
                                    <div style={{ cursor: "pointer" }}>
                                      <div style={{ position: "relative", width: "70px", height: "70px" }}>
                                        {JSON.parse(dataProduct.product.discountColors)?.[item.color.id] &&
                                          <div className={style.ribbon} style={{ top: 0, right: 0 }} onClick={() => onChangeColor(item.color.id)}>
                                            <span className={style.ribbonSpanDiscount}>- {JSON.parse(dataProduct.product.discountColors)?.[item.color.id]}%</span>
                                          </div>
                                        }
                                        <Avatar key={`buy_color_${index}`} variant="rounded" src={item.color.image} className={item.color.id === colorId ? style.productColorBuySelected : style.productColor} onClick={() => onChangeColor(item.color.id)}></Avatar>
                                      </div>
                                      {item.color.hex}
                                    </div>
                                  ) : (
                                    <div className={style.outOfStockColor}>
                                      <div style={{ position: "relative", width: "70px", height: "70px" }}>
                                        <div>
                                          <div className={style.ribbon} style={{ top: 0, right: 0 }}><span className={style.ribbonSpan}>ESAURITO</span></div>
                                          <Avatar key={`buy_color_${index}`} variant="rounded" src={item.color.image} className={item.color.id === colorId ? style.productColorBuySelected : style.productColor} ></Avatar>
                                        </div>
                                      </div>
                                      {item.color.hex}
                                    </div>
                                  )

                                );

                              })
                            ) : (
                              "non ci sono colori"
                            )
                          }
                        </div>
                        <div className={style.gridSize}>
                          {
                            JSON.parse(dataProduct.product.outOfStock)[indexOfColor] !== undefined && JSON.parse(dataProduct.product.outOfStock)[indexOfColor].values?.length > 0 && JSON.parse(dataProduct.product.outOfStock)[indexOfColor].values.map((item, index) => {
                              return (
                                <div key={index + "sizes"} style={{ textTransform: "capitalize" }}>
                                  <div>
                                    <b>{item.name}</b>
                                  </div>
                                  <TextField
                                    // value={formAddToCart?.values[formAddToCart.values.findIndex(elem => elem.sizeId === item.id)]?.quantity || ""}

                                    className={style.quantity}
                                    id="input"
                                    size="small"
                                    label={`Quantità taglia ${item.name}`}
                                    type="number"
                                    InputProps={{ inputProps: { min: 0, max: 1000 } }}
                                    onChange={event => onChangeQuantity(event, item.id, item.quantity)}
                                    placeholder={`Inserire la quantità che si vuole acquistare per la taglia ${item.name}`}
                                    InputLabelProps={{ shrink: true }}
                                    //InputProps={{ inputProps: { max: parseInt(item.quantity), min: 0 } }}
                                    variant="outlined"
                                    disabled={dataProduct.product.colorFolder.colors.length === 0 || dataProduct.product.groupSize.sizes.length === 0 || dataProduct.product.stock === 1 ? true : false}
                                  />
                                </div>
                              )
                            })
                          }
                        </div>
                        <div className={style.gridAddToCart}>
                          <Button
                            className={style.buyButton}
                            size="medium"
                            variant="contained"
                            color="primary"
                            onClick={onAddOnCart}
                            disabled={total === 0 ? true : false}
                          >
                            {!hidePrice ? `€${total} Aggiungi` : "Aggiungi"}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <div>
                    <Typography variant="subtitle1" className={style.productBuy}>Prodotto Esaurito</Typography>
                  </div>
                )
              }

              <div>
                <Typography variant="subtitle1" className={style.productSummary}>Riepilogo acquisto</Typography>
                <Typography variant="caption" color="error" className={style.productSummary} ><b>ATTENZIONE:</b> Le quantità potrebbero essere modificate in base alla disponibilità in magazzino</Typography>
              </div>

              <div className={style.productSummaryContainer}>
                {
                  dataSummaryProductOnCart && dataSummaryProductOnCart.getSummaryProductOnCart.length > 0 ? (
                    <>
                      <TableContainer >
                        <Table className={style.table} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="left"><b>Taglia</b></StyledTableCell>
                              <StyledTableCell align="left"><b>Colore</b></StyledTableCell>
                              <StyledTableCell align="left"><b>Codice Colore</b></StyledTableCell>
                              <StyledTableCell align="left"><b>Quantità</b></StyledTableCell>
                              {!hidePrice &&
                                <>
                                  <StyledTableCell align="left"><b>Prezzo</b></StyledTableCell>
                                  <StyledTableCell align="left"><b>Sconto</b></StyledTableCell>
                                  <StyledTableCell align="left"><b>Totale</b></StyledTableCell>
                                </>
                              }
                              <StyledTableCell align="left" ><b>Azioni</b></StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              dataSummaryProductOnCart.getSummaryProductOnCart.map((item, index) => (
                                <StyledTableRow key={index + "rowTable"}>
                                  <TableCell align="left">{item.size}</TableCell>
                                  <TableCell align="left"><Avatar variant="rounded" src={item.color} className={style.productColorSelect}></Avatar></TableCell>
                                  <TableCell align="left">{item.hex}</TableCell>
                                  <TableCell align="left">{item.quantity}</TableCell>
                                  {!hidePrice &&
                                    <>
                                      <TableCell align="left">€{dataProduct.product.sale && dataProduct.product.sale !== 0 ? dataProduct.product.sale : dataProduct.product.price}</TableCell>
                                      <TableCell align="left">{dataProduct.product.percentage_discount && dataProduct.product.percentage_discount !== 0 ?
                                        `${dataProduct.product.percentage_discount}%` : (JSON.parse(dataProduct.product.discountColors)?.[item.color_id] ? `${JSON.parse(dataProduct.product.discountColors)?.[item.color_id]}%` : '')}</TableCell>
                                      <TableCell align="left">€{item.total}</TableCell>
                                    </>
                                  }
                                  <TableCell align="left">
                                    <IconButton aria-label="delete" color="secondary" className={style.deleteButton} onClick={() => onRemoveOnCart(item.id)}>
                                      <ClearIcon />
                                    </IconButton>
                                  </TableCell>
                                </StyledTableRow>
                              ))
                            }
                            {!hidePrice &&
                              <StyledTableRow>
                                <TableCell colSpan={5} />
                                <TableCell align="left"><b>Costo Totale</b></TableCell>
                                <TableCell align="left">€{(dataSummaryProductOnCart.getSummaryProductOnCart.reduce((a, b) => a + b.total, 0)).toFixed(2)}</TableCell>
                              </StyledTableRow>
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <div className={style.cartButtonContainer}>
                        <Button
                          className={style.cartButton}
                          size="medium"
                          variant="contained"
                          color="primary"
                          onClick={onGoToCart}
                          startIcon={<ShoppingCartIcon />}
                        >Vai al Carrello</Button>
                      </div>
                    </>
                  ) : (<Typography variant="body2" >Non hai ancora aggiunto questo prodotto al carrello</Typography>)
                }
              </div>
            </Paper>
          </div>
        </Grid>
        <AppBar position="static" className={style.tab}>
          <Tabs
            aria-label="simple tabs example"
            value={tabValue}
            indicatorColor="secondary"
          >
            <Tab label="Finezza" onClick={() => onChangeTab(0)} />
            <Tab label="Peso" onClick={() => onChangeTab(1)} />
            {/* <Tab label="Descrizione" onClick={() => onChangeTab(2)} /> */}
          </Tabs>
        </AppBar>
        <TabPanel value={tabValue} index={0} >
          {dataProduct.product.fineness_description}
        </TabPanel>
        <TabPanel value={tabValue} index={1} >
          {dataProduct.product.weight_description}
        </TabPanel>
        {/* <TabPanel value={tabValue} index={2} >
          {dataProduct.product.description}
        </TabPanel> */}
      </div >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={successAddToCart || warningAddToCart}
        onClose={onCloseSuccessAddToCart}
        autoHideDuration={2000}
      //key={{vertical: "top", horizontal: "center"}}
      >
        <Alert onClose={onCloseSuccessAddToCart} severity={warningAddToCart ? "warning" : "success"} elevation={6} variant="filled">
          {warningAddToCart ? "ATTENZIONE: Quantità richieste non disponibili" : "Prodotto aggiunto al carrello"}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={errorAddToCart}
        onClose={onCloseErrorAddToCart}
        autoHideDuration={2000}
      //key={{vertical: "top", horizontal: "center"}}
      >
        <Alert onClose={onCloseErrorAddToCart} severity="error" elevation={6} variant="filled">
          Non è stato possibile aggiungere il prodotto al carrello
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={successRemoveOnCart}
        onClose={onCloseSuccessRemoveOnCart}
        autoHideDuration={2000}
      //key={{vertical: "top", horizontal: "center"}}
      >
        <Alert onClose={onCloseSuccessRemoveOnCart} severity="success" elevation={6} variant="filled">
          Prodotto rimosso dal carrello
        </Alert>
      </Snackbar>
      <Modal
        open={modalZoomColor}
        onClose={closeModalZoomColor}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={style.modal}
      >
        <div className={style.modalBody}>
          <div className={style.modalTitle}>
            <Typography component="h1" variant="h5" align="center">{colorToZoom.colorHex}</Typography>
            <span className={style.modalClose} onClick={closeModalZoomColor}>x</span>
          </div>
          <div className={style.modalContent}>
            <img src={colorToZoom.colorImage} className={style.modalColor} alt="a color to zoom" />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Layout;
