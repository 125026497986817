import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  messageWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: "1fr",
    }
  },
}));
