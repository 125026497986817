import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  modal:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalBody:{
    padding: "0.5rem 1rem",
    backgroundColor: "#f9f9f9",
    borderRadius: "5px",
    width: "450px",
    outline: "none"
  },
  modalTitle:{
    marginBottom: "2rem",
    position: "relative",
  },
  modalClose : {
    position: "absolute",
    top: "-0.25rem",
    display: "block",
    right: "0rem",
    fontSize: "1.75rem",
    cursor: "pointer",
  },
  modalFooter:{
    marginTop: "2rem",
    marginBottom: "0.5rem",
  },
}));
