import React from "react";
import Style from "./style.js";


const PromoBanner = () => {
  const style = Style();

  return (
    <div
      style={{
        background: "white",
        border: "1px solid #dedede",
        borderRadius: "6px",
        padding: "0 20px",
        margin: "0 0 20px",
        boxShadow: "3px 3px 4px #00000014",
      }}
    >
      <div className={style.messageWrapper}>
        <div>
          <h2 style={{ color: "#00a500" }}>🎉 PROMOZIONE ESCLUSIVA DONNA E UOMO 🎉</h2>
          <p>Acquista un minimo di <b>€1.500</b> + iva di maglieria <b>DONNA</b> o <b>€500,00</b> + iva di maglieria <b>UOMO</b> e accedi alla speciale scontistica dedicata.</p>
          <p><i>Aggiungi ora i tuoi articoli al carrello e approfitta dello sconto!</i></p>
        </div>
        <div>
          <h2 style={{ color: "#00a500" }}>🎉 EXCLUSIVE WOMEN'S AND MEN'S PROMOTION 🎉</h2>
          <p>Purchase a minimum of <b>€1.500</b> + VAT of <b>WOMENS'S</b> knitwear or <b>€500,00</b> + VAT of <b>MEN'S</b> knitwear and access the special discount.</p>
          <p><i>Add your items to the cart now and take advantage of the discount!</i></p>
        </div>
      </div>
    </div>
  );
};

export default PromoBanner;
