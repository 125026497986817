import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Style from "./style.js";
import { Button } from "@material-ui/core";

// Funzioni di gestione dei cookie
const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = `${name}=${value}; ${expires}; path=/`;
};

const getCookie = (name) => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    if (cookie.indexOf(name + "=") === 0) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};

const PromoPopup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const style = Style();

  useEffect(() => {
    // Controlla se il cookie è presente
    const promoClosed = getCookie("promoPopupClosed");
    if (!promoClosed) {
      setIsVisible(true); // Mostra il popup se il cookie non esiste
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    setCookie("promoPopupClosed", "true", 1); // Valido per 1 giorno (24 ore)
  };

  return (
    <Modal
      open={isVisible}
      onClose={handleClose}
      aria-labelledby="promo-popup-title"
      aria-describedby="promo-popup-description"
      className={style.modal}
    >
      <div className={style.modalBody}>
        <div className={style.modalTitle}>
          <span className={style.modalClose} onClick={handleClose}>
            x
          </span>
        </div>
        <div className={style.modalContent}>
          <h3 style={{ color: "#00a500" }}>🎉 PROMOZIONE ESCLUSIVA DONNA E UOMO 🎉</h3>
          <p>
            Acquista un minimo di <b>€1.500</b> + iva di maglieria <b>DONNA</b> o <b>€500,00</b> + iva di maglieria <b>UOMO</b> e accedi alla speciale scontistica dedicata.
          </p>
          <p>
            <i>
              Aggiungi ora i tuoi articoli al carrello e approfitta dello
              sconto!
            </i>
          </p>
          <h3 style={{ color: "#00a500" }}>🎉 EXCLUSIVE WOMEN'S AND MEN'S PROMOTION 🎉</h3>
          <p>
            Purchase a minimum of <b>€1.500</b> + VAT of <b>WOMENS'S</b> knitwear or <b>€500,00</b> + VAT of <b>MEN'S</b> knitwear and access the special discount.
          </p>
          <p>
            <i>
              Add your items to the cart now and take advantage of the discount!
            </i>
          </p>
        </div>
        <div className={style.modalFooter}>
          <Button
            size="large"
            type="submit"
            fullWidth
            variant="contained"
            onClick={handleClose}
            color="primary"
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              Chiudi messaggio<br /><span style={{ fontSize: "11px" }}>(close message)</span>
            </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PromoPopup;




// import React, { useState, useEffect } from "react";
// import Modal from "@material-ui/core/Modal";
// import Style from "./style.js";
// import { Button } from "@material-ui/core";

// const PromoPopup = () => {
//   const [isVisible, setIsVisible] = useState(false);
//   const style = Style();


//   useEffect(() => {
//     const promoClosed = localStorage.getItem("promoPopupClosed");
//     if (!promoClosed) {
//       setIsVisible(true);
//     }
//   }, []);

//   const handleClose = () => {
//     setIsVisible(false);
//     localStorage.setItem("promoPopupClosed", "true");
//   };

//   return (
//     <Modal
//       open={isVisible}
//       onClose={handleClose}
//       aria-labelledby="promo-popup-title"
//       aria-describedby="promo-popup-description"
//       className={style.modal}
//     >
//       <div className={style.modalBody}>
//         <div className={style.modalTitle}>
//           <span className={style.modalClose} onClick={handleClose}>x </span>
//         </div>
//         <div className={style.modalContent}>
//           <h3>🎉 PROMOZIONE ESCLUSIVA DONNA! 🎉</h3>
//           <p>Acquista un minimo di € 1.500 + IVA di maglieria donna e accedi alla speciale scontistica dedicata.</p>
//           <p><i>Aggiungi ora i tuoi articoli al carrello e approfitta dello sconto!</i></p>
//         </div>
//         <div className={style.modalFooter}>
//           <Button
//             size="large"
//             type="submit"
//             fullWidth
//             variant="contained"
//             onClick={handleClose}
//             color="primary"
//           >
//             Chiudi messaggio
//           </Button>
//         </div>
//       </div>
//     </Modal>
//   );
// };

// export default PromoPopup;
