/* eslint-disable array-callback-return */

import React from "react";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import StorefrontIcon from "@material-ui/icons/Storefront";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import { withStyles } from "@material-ui/core/styles";
import Style from "./style.js";
import PromoBanner from "../../../../components/promoBanner/index.js";

const ColorButton = withStyles((theme) => ({
  root: {
    color: "black",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#ffffff",
      opacity: 0.8,
    },
  },
}))(Button);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const noItemOnCart = (style, onGoToShop) => (
  <div className={style.paperNotFoundContent}>
    <p className={style.paperNotFoundText}>Opsss...</p>
    <span className={style.paperNotFoundEmoji} role="img" aria-label="emoji-thinking" aria-labelledby="emoji-thinking">🛒</span>
    <p style={{ marginBottom: "1.5rem" }}>Non ci sono prodotti nel carello!</p>
    <Button
      className={style.resetFilterButton}
      size="small"
      variant="contained"
      color="secondary"
      onClick={onGoToShop}
      startIcon={<StorefrontIcon />}
    >Vai al negozio</Button>
  </div>
);

const successCreateOrderResponse = (style, onGoToShop, responseCreateOrder, history) => (
  <div className={style.paperNotFoundContent}>
    {responseCreateOrder.status === 1 ? (
      <>
        <p className={style.paperNotFoundText}>Il tuo Ordine è stato emanato con successo.</p>
        {(responseCreateOrder && responseCreateOrder.failed_product.length > 0) && (
          <Paper style={{ width: "max-content", margin: "auto", padding: "30px" }}>
            <Typography variant="h4" color="error"><b>ATTENZIONE</b></Typography>
            {responseCreateOrder.failed_product.map((item) => (
              <Typography>• {item}</Typography>
            ))}
          </Paper>
        )}
        <span className={style.paperNotFoundEmoji} role="img" aria-label="emoji-thinking" aria-labelledby="emoji-thinking">📦</span>
        <p style={{ marginBottom: "1.5rem" }}>Controlla la tua casella di posta per ulteriori informazioni!</p>
        <Button
          className={style.resetFilterButton}
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => history.push("/order-detail/" + responseCreateOrder.order_id)}
          startIcon={<StorefrontIcon />}
        >Vai al dettaglio Ordine</Button>
      </>
    ) : (
      <>
        <Typography variant="h4" color="error"><b>ATTENZIONE: ordine non emesso i prodotti richiesti sono esauriti.</b></Typography>
        <Button
          className={style.resetFilterButton}
          size="small"
          variant="contained"
          color="secondary"
          onClick={onGoToShop}
          startIcon={<StorefrontIcon />}
        >Torna al negozio</Button>
      </>
    )}
  </div>
);

const Layout = ({
  dataMe,
  dataMyCart,
  onRemoveOnCart,
  onCreateOrder,
  successRemoveOnCart,
  onCloseSuccessRemoveOnCart,
  onGoToShop,
  onGoToProduct,
  successCreateOrder,
  modalChangeQuantity,
  openModalChangeQuantity,
  closeModalChangeQuantity,
  onChangeQuantity,
  formChangeQuantity,
  onConfirmChangeQuantity,
  onChangeOrderNote,
  orderNote,
  responseCreateOrder,
  history,
  warningQuantity,
}) => {
  const style = Style();
  var arrId = [];
  var index = 0;
  var totalQuantity = 0;
  dataMyCart.myCart.map((item) => {
    var exist = arrId.find(element => element.product_id === item.product.id);
    if (exist) {
      arrId.push({
        product_id: item.product.id,
        index: exist.index,
      });
    } else {
      arrId.push({
        product_id: item.product.id,
        index: index,
      });
      index++;
    }
  });
  return (
    <>
      {
        successCreateOrder === false ? (
          dataMyCart && dataMyCart.myCart.length > 0 ? (
            <>
              <div style={{ marginBottom: "1rem", marginTop: "1rem" }}>
                <PromoBanner />
                <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "1rem" }}>
                  <Typography color="inherit" className={style.breadCrumbsText} onClick={onGoToShop}>Shop</Typography>
                  <Typography color="textPrimary">Carrello</Typography>
                </Breadcrumbs>
                <TableContainer component={Paper} >
                  <Table className={style.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left"><b>Immagine</b></StyledTableCell>
                        <StyledTableCell align="left"><b>Nome</b></StyledTableCell>
                        <StyledTableCell align="left"><b>Codice</b></StyledTableCell>
                        <StyledTableCell align="left"><b>Taglia</b></StyledTableCell>
                        <StyledTableCell align="left"><b>Colore</b></StyledTableCell>
                        <StyledTableCell align="left"><b>Codice Colore</b></StyledTableCell>
                        <StyledTableCell align="left"><b>Quantita</b></StyledTableCell>
                        <StyledTableCell align="left"><b>Prezzo Unitario</b></StyledTableCell>
                        <StyledTableCell align="left"><b>Sconto</b></StyledTableCell>
                        <StyledTableCell align="left"><b>Totale</b></StyledTableCell>
                        <StyledTableCell align="left" ><b>Azioni</b></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        dataMyCart.myCart.map((item, index) => {
                          var colorIndex = arrId.find(element => element.product_id === item.product.id);
                          totalQuantity = totalQuantity + item.quantity;
                          return (
                            <TableRow key={index} className={colorIndex.index % 2 !== 0 ? style.rowWhite : style.rowGray}>
                              <TableCell align="left"><Avatar variant="rounded" className={style.productImage} src={item.product.image}></Avatar></TableCell>
                              <TableCell align="left"><Typography className={style.productName} onClick={() => onGoToProduct(item.product.id)}>
                                {item.product.name}{item.product.season?.is_preorder === 1 ? <span style={{ fontSize: "8px" }}><br />PREORDER</span> : ""}
                              </Typography></TableCell>
                              <TableCell align="left">{item.product.code}</TableCell>
                              <TableCell align="left">{item.size.name}</TableCell>
                              <TableCell align="left"><Avatar variant="rounded" src={item.color.image}></Avatar></TableCell>
                              <TableCell align="left">{item.color.hex}</TableCell>
                              <TableCell align="left">{item.quantity} <span onClick={() => openModalChangeQuantity(item.id, item.product.id, item.product_price, item.quantity, item.quantity_max)} style={{ color: "blue", cursor: "pointer" }}>modifica</span></TableCell>
                              <TableCell align="left">€{item.discount && item.discount !== "" ? (item.original_price).toFixed(2) : (item.product_price).toFixed(2)}</TableCell>
                              <TableCell align="left">{item.discount && item.discount !== "" ? `${item.discount}%` : ""}</TableCell>
                              <TableCell align="left">€{item.total}</TableCell>
                              <TableCell align="left">
                                <IconButton aria-label="delete" color="secondary" className={style.deleteButton} onClick={() => onRemoveOnCart(item.id, item.product.id)}>
                                  <ClearIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      }
                      <StyledTableRow>
                        <TableCell colSpan={7} />
                        <TableCell align="left"><b>Sub Totale</b></TableCell>
                        <TableCell align="left">€{(dataMyCart.myCart.reduce((a, b) => a + b.total, 0)).toFixed(2)}</TableCell>
                      </StyledTableRow>
                      {dataMe.me.vat_number_nationality === 0 &&
                        <StyledTableRow>
                          <TableCell colSpan={7} />
                          <TableCell align="left"><b>Iva</b></TableCell>
                          <TableCell align="left">€{((dataMyCart.myCart.reduce((a, b) => a + b.total, 0)) * 0.22).toFixed(2)}</TableCell>
                        </StyledTableRow>
                      }
                      <StyledTableRow>
                        <TableCell colSpan={7} />
                        <TableCell align="left"><b>Totale</b></TableCell>
                        <TableCell align="left">
                          €{
                            dataMe.me.vat_number_nationality === 0 ? (
                              ((Number(dataMyCart.myCart.reduce((a, b) => a + b.total, 0)) * 0.22) + Number(dataMyCart.myCart.reduce((a, b) => a + b.total, 0))).toFixed(2)
                            ) : (
                              dataMyCart.myCart.reduce((a, b) => a + b.total, 0).toFixed(2)
                            )
                          }
                        </TableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <TableCell colSpan={7} />
                        <TableCell align="left"><b>N. Pezzi</b></TableCell>
                        <TableCell align="left">{totalQuantity}</TableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid container spacing={3} className={style.gridButton}>
                  <Grid item xs={12} sm={10}>
                    <Typography variant="body1" gutterBottom fullWidth><b>
                      SPESE DI SPEDIZIONE
                    </b></Typography>
                    <Typography variant="body1" gutterBottom style={{ marginBottom: "2px" }}>
                      • <b>ITALIA: </b> € 8,50 + IVA e gratuite per ordini superiori a € 500,00 IVA inclusa.
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{ marginBottom: "2px" }}>
                      • <b>INTRA CEE: </b> € 16,00 e gratuite per ordini superiori a € 800,00.
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{ marginBottom: "2px" }}>
                      • <b>EXTRA CEE: </b> Da preventivare.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Typography className={style.productName} align='center' style={{ width: '100%' }}>NOTE ORDINE</Typography>
                    <TextareaAutosize
                      rowsMax={2}
                      aria-label="maximum height"
                      placeholder="Nota per l'ordine"
                      defaultValue={orderNote}
                      onChange={onChangeOrderNote}
                      rowsMin={3}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={10} >
                    <Typography variant="body1" gutterBottom style={{ marginBottom: "2px" }}>
                      • Possono essere effettuate sostituzioni inserendo nel campo note soprastante il numero dei capi/articolo che intendete rendere effettuando un 20% in più di riassortimento rispetto al valore della merce che intendete sostituire, il ritiro della merce che intendete rendere verrà prenotato, con corriere BRT spa per il giorno lavorativo successivo al ricevimento dell’ordine, nel caso in cui riscontrassimo inesattezze nella compilazione dell’ordine in sostituzione, verrete contattati. Ricordiamo inoltre di inserire una copia del DDT di reso all’interno del pacco.
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{ marginBottom: "2px" }}>
                      • Le sostituzioni potranno essere effettuate entro e non oltre l'inizio dei saldi stagionali.
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{ marginBottom: "2px" }}>
                      • Si concedono un massimo di 2 sostituzioni a stagione.
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{ marginBottom: "2px" }}>
                      • Le merci primaverili possono essere sostituite solo ed esclusivamente con altra merce primaverile.
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{ marginBottom: "2px" }}>
                      • Per le sostituzioni merci non verranno applicati eventuali sconti previsti su gli articoli.
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{ marginBottom: "2px" }}>
                      • Eventuali sconti previsti su gli articoli non sono cumulabili con sconti pagamento.
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{ marginTop: "16px" }}>
                      Gentile cliente, grazie per il suo prezioso ordine, quanto prima il nostro ufficio commerciale le invierà una e-mail
                      con la conferma d'ordine riepilogando tutte le condizioni commerciali a lei riservate.
                      Per qualsiasi richiesta può contattarci ai seguenti recapiti:
                    </Typography>
                    <div className={style.contact}>
                      <div>
                        <Typography variant="body1" gutterBottom>
                          Mail:
                        </Typography>
                        <div>
                          <Link href="mailto:amministrazione@blucashmere.eu" >
                            info@blucashmere.eu
                          </Link>
                        </div>
                        <div style={{ marginTop: "0.5rem" }}>
                          <Link href="mailto:business@blucashmere.eu" >
                            commerciale@blucashmere.eu
                          </Link>
                        </div>

                      </div>
                      <div>
                        <Typography variant="body1" gutterBottom>
                          Telefono:
                        </Typography>
                        <Link href="tel:0758038799" >
                          075-8038799
                        </Link>
                      </div>
                      <div>
                        <Typography variant="body1" gutterBottom>
                          Fax:
                        </Typography>
                        <Link>
                          075-8099925
                        </Link>
                      </div>
                      <div>
                        <Typography variant="body1" gutterBottom>
                          WhatsApp:
                        </Typography>
                        <Link href="tel:0758038799">
                          075-8038799
                        </Link>
                      </div>
                      <div>
                        <Typography variant="body1" gutterBottom>
                          Cellulare:
                        </Typography>
                        <Link href="tel:3664881133">
                          366-4881133
                        </Link>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={2} className={style.gridButtonContainer}>
                    <Button
                      fullWidth
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={onCreateOrder}
                      className={style.orderButton}
                      startIcon={<CheckIcon />}
                    >
                      Conferma ordine
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </>
          ) : (noItemOnCart(style, onGoToShop))
        ) : (successCreateOrderResponse(style, onGoToShop, responseCreateOrder, history))
      }
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={successRemoveOnCart}
        onClose={onCloseSuccessRemoveOnCart}
        autoHideDuration={2000}
        key={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={onCloseSuccessRemoveOnCart} severity="success" elevation={6} variant="filled">
          Prodotto rimosso dal carello
        </Alert>
      </Snackbar>
      <Modal
        open={modalChangeQuantity}
        onClose={closeModalChangeQuantity}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={style.modal}
      >
        <div className={style.modalBody}>
          <div className={style.modalTitle}>
            <Typography component="h1" variant="h5" align="center">Modifica quantità</Typography>
            <span className={style.modalClose} onClick={closeModalChangeQuantity}>x</span>
          </div>
          <div className={style.modalContent}>
            <TextField
              className={style.quantity}
              id="outlined-number"
              size="small"
              label="Quantità"
              type="number"
              onChange={event => onChangeQuantity(event)}
              value={formChangeQuantity.quantity}
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputProps: { min: 1 } }}
              variant="outlined"
              fullWidth
            />
          </div>
          <div className={style.modalFooter}>
            {warningQuantity && (<Typography variant="caption" color="error">La quantità richiesta non è disponibile</Typography>)}
            {!warningQuantity && (
              <ColorButton
                style={{ backgroundColor: "#472920", color: "#ffffff" }}
                size="large"
                type="submit"
                fullWidth
                onClick={onConfirmChangeQuantity}
                variant="contained"
                disabled={formChangeQuantity.quantity < 1 || formChangeQuantity.quantity === null ? true : false}
              >
                Vai
              </ColorButton>
            )}

          </div>
        </div>
      </Modal>
    </>
  );
};

export default Layout;
